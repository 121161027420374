.radioLeftDivStyle {
    display: inline-block;
    border-radius: 3px 0px 0px 3px;
    width: 100%;
    max-width: 200px;
    padding-bottom: 5px;
    padding-top: 5px;
    border: 1px solid #DAE2EB;
    background-color: #FFF;
    color: #798899;
}
.radioCenterDivStyle {
    display: inline-block;
    width: 100%;
    max-width: 200px;
    padding-bottom: 5px;
    padding-top: 5px;
    border: 1px solid #DAE2EB;
    background-color: #FFF;
    color: #798899;
}
.radioRightDivStyle  {
    display: inline-block;
    border-radius: 0px 3px 3px 0px;
    width: 100%;
    max-width: 200px;
    padding-bottom: 5px;
    padding-top: 5px;
    border: 1px solid #DAE2EB;
    background-color: #FFF;
    color: #798899;
}
