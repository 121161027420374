.form {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    text-align: left;
    max-width: 650px
}
.subSection {
    font-family: 'Source Sans Pro',sans-serif;
    color: #536273;
    width: 100%;
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 5px;


}
.formDiv {
    width: 100%;
    margin-top: 30px;
}
