.list {
    Webkit-box-shadow: 0 1px 3px 0 #CED8E4;
    moz-box-shadow: 0 1px 3px 0 #CED8E4;
    border: 1px solid #2DAC91;
    z-index: 1100;
    padding: 0;
    position: absolute;
    width: 100%;
    max-width: 300px;
    background: #FFF
}
